import { reCaptchaInstance, unauthedInstance } from './api-base';

interface IUnauthedApi {
  // getThemeConfig: () => Promise<BeamoNS.TBeamoTheme>;
  getClientIP: () => Promise<{ country: string }>;
  authenticationsLogin: (params: { email: string; verifyCode: string; authCode?: string }) => Promise<{
    email: string;
    token: string;
  }>;
  verifyCodeValidate: (params: { email: string; verifyCode: string }) => Promise<{ token: string; email: string }>;
  verifyCodeEmail: (params: {
    email: string;
    connectRequestId?: string;
    offrampRequestId?: string;
    paymentPageId?: string;
  }) => Promise<{ email: string }>;
  sendUserEvent: (params: { livemode: boolean; email: string; type: 'login' | 'logout' }) => Promise<any>;
  syncAccountCreation: (params: { email: string; authUid: string }) => Promise<void>;
}

const createUnauthedApi = (): IUnauthedApi => {
  const instance = unauthedInstance;

  return {
    // getThemeConfig() {
    //   return instance.get(`/themes/client/wallet`);
    // },
    getClientIP() {
      return instance.get(`/ip`);
    },
    authenticationsLogin(params) {
      // delete all undefined values from params
      Object.entries(params).forEach(
        ([key, value]) => (value === undefined || value === '') && delete params[key as keyof typeof params]
      );
      return instance.post('/authentications/login', params);
    },
    verifyCodeValidate(params) {
      return instance.post('/authentications/verify_code/validate', params);
    },
    verifyCodeEmail(params) {
      // delete all undefined values from params
      Object.entries(params).forEach(
        ([key, value]) => (value === undefined || value === '') && delete params[key as keyof typeof params]
      );
      return reCaptchaInstance.post('/authentications/verify_code/email', params);
    },
    async sendUserEvent(params): Promise<any> {
      try {
        return await instance.post('/user_events/client', params, { timeout: 3000 });
      } catch (error) {
        console.warn('Failed to send user event', error);
      }
    },
    syncAccountCreation(params) {
      return instance.post('/accounts/sync', params);
    },
  };
};

export const unauthedApi = createUnauthedApi();
