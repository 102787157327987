export const enum EHttpRequestHeader {
  MERCHANT = 'x-beamo-merchant',
  ACCOUNT = 'x-beamo-account',
}

export const enum EDeployTag {
  DEVELOPMENT_DP = 'devdp',
  DEVELOPMENT_QA = 'qa',
  PRODUCTION = 'prod',
}

export const enum EMode {
  LIVE = 'LIVE',
  TEST = 'TEST',
}

export const enum EQuickLinkType {
  INVOICE = 'INVOICE',
  PRODUCT = 'PRODUCT',
  OFFRAMP_REQUEST = 'OFFRAMP_REQUEST',
  // NFT = 'NFT',
  // DONATION = 'DONATION',
}

export const enum EPaymentType {
  CONTRACT_PAYMENT = 'contract',
  EVM_WALLET_PAYMENT = 'evm_wallet',
  SOLANA_PAYMENT = 'solana',
  SOLANA_WALLET_PAYMENT = 'solana_wallet',
  TRANSFER_PAYMENT = 'transfer',
  CARD_PAYMENT = 'card',
}

export const enum ESignupFlowType {
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  WAITING_CREATE_ACCOUNT = 'WAITING_CREATE_ACCOUNT',
  BUSINESS_NAME = 'BUSINESS_NAME',
  FACTOR_AUTH = 'FACTOR_AUTH',
  ACCOUNT_SECURED = 'ACCOUNT_SECURED',
  ONBOARD_SUCCESS = 'ONBOARD_SUCCESS',
}

export const enum ELoginFlowType {
  NO_ACCOUNT = 'NO_ACCOUNT',
  LOGIN_ACCOUNT = 'LOGIN_ACCOUNT',
  SECURITY_EMAIL = 'SECURITY_EMAIL',
  SECURITY_VERIFICATION = 'SECURITY_VERIFICATION',
  CANCEL_LOGIN = 'CANCEL_LOGIN',
}

export const enum ELoginInfoStatus {
  AUTHENTICATOR_ENABLED = 'AUTHENTICATOR_ENABLED',
  AUTHENTICATOR_NOT_ENABLED = 'AUTHENTICATOR_NOT_ENABLED',
  ACCOUNT_NOT_CREATED = 'ACCOUNT_NOT_CREATED',
}

export const enum ECashoutSwapStatus {
  SELLING = 0,
  REVIEW,
  SUCCESS,
  FAIL,
}

export enum EGoogleRecaptchaVersion {
  V2 = 2,
  V3 = 3,
}
