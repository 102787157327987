import { PaymentV2Skeleton } from './components/payment-page/skeleton';
import Spinner from './components/spinner';
import { isPayV2Module } from './utils/apexModuleHelper';
import { isInIframe } from './utils';

export default function GlobalLoader() {
  if (!isInIframe && isPayV2Module) {
    return <PaymentV2Skeleton />;
  }

  return (
    <div className='flex h-[100vh] items-center justify-center'>
      <Spinner size={64} />
    </div>
  );
}
