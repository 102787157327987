import { setUser } from '@sentry/react';
import { getAnalytics, logEvent as _logEvent, setUserId } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import {
  AuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithCredential,
  signInWithCustomToken,
  signInWithPopup,
  User,
} from 'firebase/auth';

import { livemodeStorageKey } from '@/constants/config';
import { isPayModule, isPayV2Module } from '@/utils/apexModuleHelper';
import { clearLocalRoleInfo } from '@/utils/role';
import { clearUniversalCookie, getUniversalCookie, LOGOUT_MARK, setUniversalCookie } from '@/utils/universalCookie';

import { unauthedApi } from './beamo/api-unauthed';

export { signInWithCustomToken } from 'firebase/auth';

const app = initializeApp({
  projectId: process.env.fb_projectId,
  apiKey: process.env.fb_apiKey,
  authDomain: process.env.fb_authDomain,
  appId: process.env.fb_appId,
  measurementId: process.env.fb_measurementId,
});

if ((isPayModule || isPayV2Module) && process.env.fb_recaptchaEnterpriseSiteKey) {
  initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(process.env.fb_recaptchaEnterpriseSiteKey),
    isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
  });
}

export const auth = getAuth(app);

export const analytics = getAnalytics(app);
export const logBreezeEvent = (eventName: string, eventParams?: { [key: string]: any }) => {
  _logEvent(analytics, eventName, eventParams);
};

export const signOutBeamo = async (from: string) => {
  if (from) {
    const email = auth.currentUser?.email;
    logBreezeEvent('sign_out', { from, email });
  }
  clearUniversalCookie();
  await auth.signOut();
};

async function checkCookieCredential() {
  const cookieToken = getUniversalCookie();
  if (cookieToken) {
    if (cookieToken === LOGOUT_MARK) {
      signOutBeamo('cookie');
    } else {
      try {
        typeof cookieToken === 'string'
          ? await signInWithCustomToken(auth, cookieToken)
          : await signInWithCredential(auth, cookieToken);
      } catch {
        /* empty */
      }
    }
  }
}

export const checkCookieCredentialPromise = checkCookieCredential();

export const accountReadyLock: {
  promise: Promise<BeamoNS.IAccountInfo> | null;
} = {
  promise: null,
};
let resolveUser: ((value: User) => void) | null = null;
export const userReadyLock = {
  promise: new Promise<User>((resolve) => {
    resolveUser = resolve;
  }),
};

let resolveMerchant: ((value: unknown) => void) | null = null;
export const merchantResolvedLock = {
  promise: new Promise((resolve) => {
    resolveMerchant = resolve;
  }),
  resolve: () => {
    resolveMerchant?.(null);
  },
};
export const resetMerchantResolvedLock = () => {
  merchantResolvedLock.promise = new Promise((resolve) => {
    resolveMerchant = resolve;
  });
};

onAuthStateChanged(auth, (user) => {
  if (user) {
    setUserId(auth, user.email || user.uid);
    resolveUser?.(user);
    if (!accountReadyLock.promise) {
      accountReadyLock.promise = import('./beamo/api-common').then(({ isAccountsReady }) => isAccountsReady());
    }
  } else {
    userReadyLock.promise = new Promise((resolve) => {
      resolveUser = resolve;
    });
    accountReadyLock.promise = null;
    localStorage.removeItem(livemodeStorageKey);
    clearLocalRoleInfo();
    resetMerchantResolvedLock();
  }
  setUser({
    id: user?.uid,
    email: user?.email || '',
  });
});

export const signinWithThirdParty = async (loginMethod: 'google' | 'apple') => {
  let provider: AuthProvider | null = null;
  if (loginMethod === 'google') {
    provider = new GoogleAuthProvider();
  } else {
    provider = new OAuthProvider('apple.com').addScope('email');
  }
  try {
    const result = await signInWithPopup(auth, provider);
    const oauthCredential = OAuthProvider.credentialFromResult(result);
    if (oauthCredential) {
      setUniversalCookie(JSON.stringify(oauthCredential.toJSON()));
    }
    return result;
  } catch (error: any) {
    /* empty */
  }
  return null;
};

export const syncAccountCreation = async (params: { email: string; authUid: string }) => {
  await unauthedApi.syncAccountCreation(params);
};
