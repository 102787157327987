import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EPay2Step } from '@/components/steps-container/pay2Steps';
import { TBreezeStep } from '@/components/steps-container/useSteps';
import { EPaymentType } from '@/constants/enum';
import { EPaymentFlow, EPaymentStatus, EWalletType } from '@/constants/payment';
import { IPaymentNetworkToken } from '@/types/payment';

export interface IPaymentState {
  // Initial query params
  paymentId: string;

  coin?: IPaymentNetworkToken;
  status: EPaymentStatus;
  statusHistory: EPaymentStatus[];
  lastPaymentType?: EPaymentType | EWalletType;
  walletType?: EWalletType;
  lastPaymentToken?: string;
  lastPaymentNetwork?: string;
  lastPaymentTokenAmount?: string;

  activeCryptoStep: TBreezeStep;
  activeCardStep: TBreezeStep;

  // the updates amount that user has to pay for
  updatedAmount: string;

  paymentFlow: EPaymentFlow;
  trackPaymentUrl?: string;
  processingMessage?: string;
  storedPaymentOption?: string;
  processingFee: string;
  iframeUrl?: string;

  emailToVerify?: string;
}

const initalStatus = EPaymentStatus.PREVIEW;

const initialState: IPaymentState = {
  paymentId: '',

  // Payment status
  coin: undefined,
  status: initalStatus, //initialStatus
  statusHistory: [],

  activeCryptoStep: EPay2Step.PAY_WITH_TABS,
  activeCardStep: EPay2Step.PAY_WITH_TABS,

  // Updated amount
  updatedAmount: '',

  paymentFlow: EPaymentFlow.Wallet,
  processingFee: '0',
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    // Goes back to the topmost status in history
    previousState: (state, action: PayloadAction<Partial<IPaymentState> | undefined>) => {
      const newHistory = [...state.statusHistory];
      const previousStatus = newHistory.pop() || initalStatus;
      return {
        ...state,
        ...action.payload,
        status: previousStatus,
        statusHistory: newHistory,
      };
    },
    // Skips the status in the history
    skipState: (state, action: PayloadAction<Partial<IPaymentState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    // Stores the status in the history
    nextState: (state, action: PayloadAction<Partial<IPaymentState>>) => {
      return {
        ...state,
        ...action.payload,
        statusHistory: [...state.statusHistory, state.status],
      };
    },
    // Update other information (like product response)
    updateState: (state, action: PayloadAction<Partial<IPaymentState>>) => {
      // Go to the next state

      return {
        ...state,
        ...action.payload,
      };
    },
    reset: () => initialState,
    resetPaymentState: (state, { payload: skipPreview }: PayloadAction<boolean | undefined>) => {
      const newHistory = skipPreview ? [] : [EPaymentStatus.PREVIEW];
      return {
        ...state,
        coin: undefined,
        status: EPaymentStatus.PAYMENT_METHOD, // Go back to payment method
        paymentId: '',
        wallet: undefined,
        storedPaymentOption: '',
        statusHistory: newHistory,
      };
    },

    resetToPaymentPreviewPage: (state) => {
      return {
        ...state,
        coin: undefined,
        status: EPaymentStatus.PREVIEW,
        paymentId: '',
        wallet: undefined,
        storedPaymentOption: '',
        statusHistory: [],
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { previousState, skipState, nextState, updateState, reset, resetPaymentState, resetToPaymentPreviewPage } =
  paymentSlice.actions;

export default paymentSlice.reducer;
