import { ELocalStorageKey } from '@/constants/localStorage';

export const getLocalStorageValue = (key: ELocalStorageKey) => {
  const value = localStorage.getItem(key);
  try {
    return value ? JSON.parse(value) : null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const setLocalStorageValue = (key: ELocalStorageKey, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};
